import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'sign-in',
    redirect: '/sign-in',
  },
  {
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Signin/Sign-In.vue'),
	},
  {
		path: '/sign-up',
		name: 'sign-up',
		component: () => import('../views/Registration/sign-up.vue'),
	},
  {
		path: '/select-type',
		name: 'select-type',
		component: () => import('../views/Config/supplier/select_type.vue'),
	},
  {
		path: '/declaration-type',
		name: 'declaration',
		component: () => import('../views/Config/supplier/declaration.vue'),
	},
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
